<template>
  <div class="mt-2">
    <div class="col-md-6" v-if="denominazione_impianto">
      <h3 class="ps-5">{{ denominazione_impianto }}</h3>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <router-link
          :to="`/impianti`"
          class="btn btn-light text-gray-700 fs-6 text-start"
        >
          <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista impianti
        </router-link>
      </div>
      <div
        v-if="isEnabled('fnImpiantiAddOmologazione')"
        class="col-md-6 text-end"
      >
        <div>
          <!-- <router-link
            :to="`/impianti/omologazioni-impianto/nuova-omologazione/${id}`"
          >
            <button type="button" class="btn btn-light text-gray-700 fs-6">
              <i class="bi bi-plus fs-6 text-gray-700"></i>nuova omologazione
            </button></router-link
          > -->
          <router-link
            :to="{
              name: 'nuova-omologazione-impianto',
              params: {
                id: id,
                denominazione_impianto: denominazione_impianto,
              },
            }"
            class="navi-link"
          >
            <button type="button" class="btn btn-light text-gray-700 fs-6">
              <i class="bi bi-plus fs-6 text-gray-700"></i>nuova omologazione
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <br />
    <TableOmologazioniImpianto
      :id_impianto="id"
      :denominazione_impianto="denominazione_impianto"
    />
    <br />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableOmologazioniImpianto from "@/components/components-fit/impianti/TableOmologazioniImpianto.vue";
import { useRoute } from "vue-router";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "OmologazioneImpiantiList",
  components: {
    TableOmologazioniImpianto,
  },
  props: {
    denominazione_impianto: {
      type: String,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Omologazioni impianto", ["Lista impianti"]);
    });

    const route = useRoute(); // prende id
    const id = ref(route.params.id_impianto);

    return { id, isEnabled };
  },
});
</script>

<style scoped>
.dropdown-menu {
  min-width: 200px;
}
</style>
